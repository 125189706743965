import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppState } from '@store/reducers';
import * as AppCoreActions from '@store/app-core/app-core.actions';
import * as AppCoreSelectors from '@store/app-core/app-core.selectors';
import { catchError, concatMap, map, tap, withLatestFrom } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CommonService } from '@core/services/resource/common.service';
import { IMentionUser } from '@models/user';
import { of } from 'rxjs';
import { FuseConfigService } from '../../../../@fuse/services/config';
import { AuthDispatchers } from '@store/auth';
import { SettingsSelectors } from '@store/settings';

@Injectable()
export class AppCoreEffects {
    naviateToDashboard = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AppCoreActions.navigateToDashboard),
                withLatestFrom(this.store.select(AppCoreSelectors.getBaseUrl)),
                map(([action, url]) => {
                    this.router.navigateByUrl(url + '/dashboard');
                })
            ),
        { dispatch: false }
    );

    navigateToUrl = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AppCoreActions.navigateToUrl),
                withLatestFrom(this.store.select(AppCoreSelectors.getBaseUrl), this.settingsSelectors.organisation$),
                map(([action, url, organisation]) => {
                    this.router.navigateByUrl(
                        `o/${organisation.slug}` + url + (action.url.charAt(0) === '/' ? '' : '/') + action.url
                    );
                })
            ),
        { dispatch: false }
    );

    changeScheme = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AppCoreActions.changeScheme),
                tap(action => {
                    this._fuseConfigService.config = { scheme: action.scheme };
                    if (!action.silent) {
                        this.authDispatchers.updateUser({ settings: { scheme: action.scheme } });
                    }
                })
            ),
        { dispatch: false }
    );

    loadMentions = createEffect(() =>
        this.actions$.pipe(
            ofType(AppCoreActions.loadMentions),
            withLatestFrom(this.store.select(AppCoreSelectors.getType), this.store.select(AppCoreSelectors.getSlug)),
            concatMap(([action, type, slug]) => {
                if (type && slug) {
                    return this.commonService.loadMentions(type, slug).pipe(
                        map((mentions: IMentionUser[]) => AppCoreActions.loadMentionsSuccess({ mentions })),
                        catchError(error => of(AppCoreActions.loadMentionsFailure))
                    );
                } else {
                    return of(AppCoreActions.loadMentionsFailure());
                }
            })
        )
    );

    constructor(
        public actions$: Actions,
        private router: Router,
        public store: Store<AppState>,
        private _fuseConfigService: FuseConfigService,
        private commonService: CommonService,
        private authDispatchers: AuthDispatchers,
        private settingsSelectors: SettingsSelectors
    ) {}
}
